import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpoint,
  useDisclosure,
} from '@chakra-ui/react';
import type { FC } from 'react';
import React from 'react';
import { OutboundLink } from 'react-ga';
import '../App.scss';
import placement from '../assets/beliggenhed.png';
import estrupsgadeGammel from '../assets/estrupsgade-gammel.png';
import quality from '../assets/kvalitet.png';
import lol from '../assets/bed.jpg';
import top from '../assets/top.webp';
import useGaTracker from '../components/PageTracking';
import { SignUpForApartment } from '../components/SignUpForApartment';
import WithSpeechBubbles from '../components/bubbles';

const Home: FC = () => {
  const breakpoint = useBreakpoint();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useGaTracker();

  return (
    <>
      <Box
        bgImg={top}
        bgSize={'cover'}
        h={['70vw', '45vw']}
        w={'100%'}
        borderRadius={[0, '8px']}
      >
        <Box ml={['15vw', '10']} mt={['20vw', '26vw']}>
          <Heading
            color={'white'}
            fontSize={['8vw', '4vw']}
            width={['70vw', '40vw']}
          >
            Se vores ledige boliger i Silkeborg
          </Heading>
          <OutboundLink
            eventLabel="Link til Bolig Portal"
            to="https://www.boligportal.dk/dl/toft/"
            target="_blank"
          >
            <Button
              rightIcon={<ArrowForwardIcon />}
              mt={['1vw', '1vw']}
              w={['45vw', '15vw']}
              h={['8vw', '2vw']}
              borderColor={'black'}
              fontSize={['2.6vw', '1.0vw']}
            >
              Se ledige boliger
            </Button>
          </OutboundLink>
        </Box>
      </Box>

      <Flex
        mr={{ md: '5vw' }}
        ml={{ md: '5vw' }}
        flexDirection={'column'}
        mt={{ md: '2vw' }}
      >
        {breakpoint !== 'base' ? (
          <Flex direction={['column', 'row']} h={{ md: '25vw' }}>
            <Box>
              <Image
                src={estrupsgadeGammel}
                objectFit={'cover'}
                h={['100vw', '100%']}
                w={['100%', '70vw']}
                borderLeftRadius={{ md: 8 }}
              />
            </Box>

            <Flex
              bg={'#A39E90'}
              flexDir={'column'}
              py={['6vw', '0vw']}
              borderRightRadius={{ md: 8 }}
            >
              <Heading
                fontSize={['8vw', '1.8vw']}
                mb={'1.5vw'}
                mt={'auto'}
                align="center"
              >
                Velkommen til Toft Ejendomme
              </Heading>
              <Text
                mx={'auto'}
                mb={'auto'}
                align="center"
                w={['80%', '50%']}
                fontSize={['5vw', '1.2vw']}
              >
                Toft Ejendomme råder over et samlet boligareal på 8000 m2
                fordelt på 120 boliglejemål, alle beliggende på attraktive
                placeringer i det centrale Silkeborg, med kort afstand til
                gågadens mange tilbud, offentlige transportmidler samt den
                flotte natur i og omkring Silkeborg. Toft Ejendomme blev tilbage
                i 1960erne etableret af malermester Henning Toft.
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Flex direction={['column', 'row']} h={{ md: '25vw' }}>
            <Flex
              bg={'#A39E90'}
              flexDir={'column'}
              py={['6vw', '0vw']}
              borderRightRadius={{ md: 8 }}
            >
              <Heading
                fontSize={['8vw', '1.8vw']}
                mb={'1.5vw'}
                mt={'auto'}
                align="center"
              >
                Velkommen til Toft Ejendomme
              </Heading>
              <Text
                mx={'auto'}
                mb={'auto'}
                align="center"
                w={['80%', '50%']}
                fontSize={['5vw', '1.2vw']}
              >
                Toft Ejendomme råder over et samlet boligareal på 7500 m2
                fordelt på 115 boliglejemål, alle beliggende på attraktive
                placeringer i det centrale Silkeborg, med kort afstand til
                gågadens mange tilbud, offentlige transportmidler samt den
                flotte natur i og omkring Silkeborg. Toft Ejendomme blev tilbage
                i 1960erne etableret af malermester Henning Toft.
              </Text>
            </Flex>
            <Box>
              <Image
                src={estrupsgadeGammel}
                objectFit={'cover'}
                h={['100vw', '100%']}
                w={['100%', '70vw']}
                borderLeftRadius={{ md: 8 }}
              />
            </Box>
          </Flex>
        )}

        <Flex
          mt={{ md: '2vw' }}
          flexDir={['column', 'row']}
          spacing="0px"
          align="center"
        >
          <Flex
            bg={'#D1CABD'}
            borderRadius={{ md: '8px' }}
            direction={'column'}
            h={['100vw', '35vw']}
            w={['100%', '50%']}
          >
            <Box m={'auto'} align="center">
              <Heading
                textColor={'black'}
                fontSize={['8vw', '1.8vw']}
                align={'center'}
                fontWeight={'bold'}
                w={'80%'}
              >
                Søger du en ny bolig?
              </Heading>
              <Text
                mt={'1vw'}
                textColor={'black'}
                align={'center'}
                justify={'center'}
                fontSize={['5vw', '1.2vw']}
                maxW={'50%'}
              >
                Hos Toft Ejendomme kan du gratis søge og finde ledige
                lejeboliger i Silkeborg Midtby.
                <br /> <br />
                {breakpoint !== 'base'
                  ? 'Toft Ejendomme tilbyder unikke boliger med plads til alle. Drømmer du om at bo i centrum af Silkeborg?'
                  : ''}
              </Text>
              <Button
                onClick={onOpen}
                m={'20px'}
                w={'50%'}
                bg={'#D1CABD'}
                borderColor={'black'}
                borderWidth={'thin'}
                fontSize={['5vw', '1.3vw']}
              >
                Skriv dig op
              </Button>
            </Box>
          </Flex>
          <Image
            w={['100vw', '50%']}
            h={['100vw', '30vw']}
            objectFit={'cover'}
            src={lol}
            borderRightRadius={{ md: 8 }}
          />
        </Flex>
       {/*  <Flex
          bg={'#A39E90'}
          borderRadius={{ md: '8px' }}
          mt={{ md: '2vw' }}
          flexDir={['column', 'column']}
          spacing="0px"
          align="center"
          pt={6}
        >
          <Heading
            textColor={'black'}
            fontSize={['8vw', '1.8vw']}
            align={'center'}
            fontWeight={'semibold'}
            w={'80%'}
          >
            Tidligere lejere siger
          </Heading>
          <Flex
            direction={'row'}
            h={['100vw', '25vw']}
            w={['100%', '100%']}
            alignItems={'center'}
            justifyContent={'space-evenly'}
          >
            <ReviewCard
              name="Janne"
              review="Jeg har som tidligere lejer hos Torben Toft kun haft positive oplevelser. Torben er fair, reel og handler lynhurtigt på henvendelser og opgaver. Så de bedste anbefalinger og 5 stjerner herfra"
            />
            <ReviewCard
              name="Gitte"
              review="Jeg har boet ved Toft Ejendomme i 3 år og har været så glad for min lejlighed og bygningen. Der er altid dejlig rent i opgangende og hvis man har brug for hjælp er viceværten hurtigt til at svare. Kan varmt anbefale Toft Ejendomme "
            />
            <ReviewCard
              name="Cecilie"
              review="Jeg har kun haft gode oplevelser med Toft ejendomme. Der har altid været et godt samarbejde og god kommunikation samt en tryg oplevelse. Jeg anbefaler stærkt Toft ejendomme"
            />
          </Flex>
        </Flex> */}
        <WithSpeechBubbles />

        <Flex
          mt={{ md: '2vw' }}
          flexBasis={'25%'}
          flexGrow={0}
          direction={{ base: 'column', md: 'row' }}
        >
          <Box
            borderRadius={{ md: '8px' }}
            bgImg={quality}
            bgSize={['auto', 'cover']}
            bgRepeat={'no-repeat'}
            w={['100vw', '100%']}
            h={['90vw', '28vw']}
          >
            <Box m={'auto'} mt={['16vw', '0']} textAlign={['center', 'left']}>
              <Heading
                fontSize={{ base: '10vw', md: '2vw' }}
                ml={{ md: '1.5vw' }}
                mt={{ md: '0.7vw' }}
                fontWeight={'semibold'}
              >
                Kvalitet
              </Heading>
              <Text
                ml={{ md: '1.5vw' }}
                mt={'0.5vw'}
                w={['70%', '15vw']}
                m="auto"
                fontSize={['5.4vw', '1.2vw']}
              >
                Vi stræber efter at vedligeholde vores ejendomme i den bedste
                kvalitet. Alle vores lejligheder fremstår flotte og
                velrenoveret.
              </Text>
            </Box>
          </Box>

          <Box w={'2vw'} />
          <Box
            borderRadius={{ md: '8px' }}
            bgImg={placement}
            bgSize={['auto', 'cover']}
            bgRepeat={'no-repeat'}
            w={['100vw', '100%']}
            h={['90vw', '28vw']}
          >
            <Box m={'auto'} mt={['16vw', '0']} textAlign={['center', 'left']}>
              <Heading
                fontSize={{ base: '10vw', md: '2vw' }}
                ml={{ md: '1.5vw' }}
                mt={{ md: '0.7vw' }}
                fontWeight={'semibold'}
                color={'white'}
              >
                Beliggenhed
              </Heading>
              <Text
                ml={{ md: '1.5vw' }}
                mt={'0.5vw'}
                w={['70%', '15vw']}
                m="auto"
                fontSize={['5.4vw', '1.2vw']}
                color={'white'}
              >
                Vores ejendomme ligger på de bedste beliggenheder i Silkeborg.
                Vi har ejendomme i Silkeborgs midt- og sydby. Ejendommene har
                kort afstand til midtbyens tilbud.
              </Text>
            </Box>
          </Box>
        </Flex>
      </Flex>
      <SignUpForApartment isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};

export default Home;
