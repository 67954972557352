import {
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import '../App.scss';

export const PrivatlivsPolitik = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  return (
    <Modal size={'full'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Information til lejere omkring behandling af personlige oplysninger
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody w={'60%'}>
          <td>
            <p>
              <br />
              <strong>TOFT EJENDOMME</strong>
            </p>
            <p>
              Udlejningsvirksomheden TOFT EJENDOMME, der er Dataansvarlig i
              forbindelse med behandlingen af virksomhedens lejeres
              personoplysninger, har følgende data og kontaktoplysninger:
              <br />
              <br />
              TOFT EJENDOMME v/Torben Toft Nielsen
              <br />
              Cvr. nr. 27582915
              <br />
              Mågevej 10
              <br />
              8600 Silkeborg
              <br />
              <a href="mailto:info@toftejendomme.dk">info@toftejendomme.dk</a>
              <br />
              <br />
              TOFT EJENDOMME vil i det følgende være benævnt ”TOFT EJENDOMME” /
              ”vi” / ”os”
              <br />
              <br />
              <br />
              <strong>Oplysninger vi behandler</strong>
              <br />
              <br />
              Dette er en nærmere orientering til dig/jer som lejere, om TOFT
              EJENDOMME’s behandling af personoplysninger.
            </p>
            <p>
              {' '}
              TOFT EJENDOMME behandler i forbindelse med lejemålet følgende
              personoplysninger om lejer:
              <br />
              <br />
              Navn
              <br />
              Adresse
              <br />
              Underskrift
              <br />
              Mailadresse
              <br />
              Telefonnummer
              <br />
              Bankkonto
              <br />
              Forbrugsdata/forbrugsmønster
            </p>
            <p>
              {' '}
              <br />
              <strong>
                Oplysningerne benytter vi i forbindelse med følgende:
              </strong>
              <br />
              <br />
              Accept af aftaler med lejer (f.eks. accept af lejekontrakt)
              <br />
              Kommunikation med lejer
              <br />
              Entydig identifikation af lejer
              <br />
              Evt. udbetalinger
              <br />
              Aflæggelse af forbrugsregnskaber
              <br />
              Mhp. generel efterlevelse af lejekontrakten.
            </p>
            <p>
              {' '}
              <br />
              <strong>
                Videregivelse til 3. mand / brug af databehandlere
              </strong>
              <br />
              <br />
            </p>
            <p>
              {' '}
              Personoplysningerne vi behandler lagres på en server hos os (dvs.
              fysisk i Silkeborg).
            </p>

            <br />
            <p>
              {' '}
              Personoplysningerne behandles dog også i et vist omfang af
              databehandlere vi benytter som led i udlejningsvirksomheden,
              ligesom oplysningerne i visse tilfælde videregives til egentlige
              3. mænd.
            </p>

            <br />
            <p> Det drejer sig nærmere om følgende databehandlere / 3. mænd:</p>

            <br />
            <p>
              {' '}
              <em>Måleraflæsnings-/forbrugsregnskabsfirma</em>
            </p>
            <p>
              {' '}
              I forbindelse med aflæggelse af forbrugsregnskaber og hermed
              forbundet arbejde, udveksles personoplysningerne i fornødent
              omfang med et måleraflæsnings-/forbrugsregnskabsfirma (i skrivende
              stund Techem Danmark A/S)
            </p>

            <br />
            <p>
              {' '}
              <em>Håndværkere</em>
            </p>
            <p>
              {' '}
              Som led i arbejde, der skal udføres i det lejede vil lejers
              kontaktoplysninger (i samråd med lejer i det konkrete tilfælde) i
              visse tilfælde blive videregivet til eksterne håndværkere, således
              at håndværkeren kan aftale et passende tidspunkt for arbejdets
              udførelse med lejer.
            </p>

            <br />
            <p>
              {' '}
              <em>Revisor</em>
            </p>
            <p>
              {' '}
              Vi anvender, ligesom så mange andre virksomheder, en revisor i
              forbindelse med dels bogføring og dels udarbejdelse af regnskab.
              <br />
              Revisor vil, som helt naturligt led i revisors arbejde, fra tid
              til anden have adgang til at se de ovenstående persondata vedr.
              vores lejere.
            </p>

            <br />
            <p>
              {' '}
              <em>Mailkontoleverandør</em>
            </p>
            <p>
              {' '}
              Vi gør naturligvis brug af e-mail i vores kommunikation med lejere
              og leverandører/3. mænd. Som følge heraf vil lejernes persondata,
              når de håndteres af os via e-mail, blive overladt i vores
              mailkontoleverandørs hænder.
            </p>

            <br />
            <p> De hensyn, der nødvendiggør vores behandling af persondata:</p>
            <p>
              {' '}
              De hensyn, der ligger bag vores behandling (som beskrevet ovenfor)
              af persondata, er følgende:
            </p>
            <br />
            <br />
            <UnorderedList>
              <ListItem>Generel efterlevelse af lejekontrakten.</ListItem>
              <ListItem>
                Håndtering af lejemålets forsyningsforhold/forsyningsregnskaber.
              </ListItem>
              <ListItem>
                Fremme en effektiv økonomisk drift af lejemålet isoleret set og
                TOFT EJENDOMME som sådan.
              </ListItem>
              <ListItem>Lette kommunikationen med vore lejere</ListItem>
            </UnorderedList>

            <br />
            <p> Vores juridiske grundlag for at behandle oplysningerne er:</p>
            <p>
              {' '}
              Først og fremmest foretager vi persondatabehandlingen af hensyn
              til at kunne indgå og leve op til lejeaftalen jfr.
              persondataforordningens artikel 6, 1., b.
            </p>

            <br />
            <p>
              {' '}
              <strong>Derudover er det juridiske grundlag følgende:</strong>
            </p>

            <br />
            <p>
              {' '}
              Persondataforordningens artikel 6, 1. a. (samtykke fra lejer)
            </p>
            <p>
              {' '}
              Persondataforordningens artikel 6., 1., f (de legitime interesser,
              der forfølges, er angivet ovenfor under ”De hensyn, der
              nødvendiggør vores behandling af persondata”)
            </p>

            <br />
            <p>
              {' '}
              <strong>Hvor længe vi opbevarer oplysningerne</strong>
            </p>

            <br />
            <p>
              {' '}
              Oplysningerne vi behandler opbevarer vi mens lejeforholdet består
              og efter lejemålets ophør indtil enhver forpligtelse mellem os og
              lejer er ophørt. Det vil typisk sige i op til 3 år efter
              lejemålets ophør.
            </p>
            <p>
              {' '}
              I det omfang oplysningerne vi opbevarer, er oplysninger, vi er
              forpligtet til at være i besiddelse af efter lovgivningen (f.eks.
              bogføringsloven eller skattelovgivningen), vil oplysningerne kunne
              blive opbevaret i en længere periode – typisk op til 5 år efter
              udløbet af det år, hvori lejemålet ophørte.
            </p>

            <br />
            <p>
              {' '}
              <strong>Lejers /dine rettigheder:</strong>
            </p>

            <br />
            <p>
              {' '}
              Du har ret til på begæring at få indsigt i, hvad vi har
              registreret af oplysninger om dig jfr. nærmere
              persondataforordningens artikel 15.
              <br />
              Du har ret til på begæring at få berigtiget eventuelle urigtige
              oplysninger om dig indsamlet af os jfr. persondataforordningens
              artikel 16.
            </p>
            <p>
              {' '}
              Du har ret til, at kræve oplysninger vi opbevarer om dig slettet,
              hvis de ikke længere er nødvendige for det formål, hvortil de blev
              indsamlet, samt i visse andre tilfælde, jfr. nærmere
              persondataforordningens artikel 17.
            </p>
            <p>
              {' '}
              Du har ret til, at kræve behandlingen af oplysninger vedr. dig
              begrænset i visse situationer, jfr. nærmere
              persondataforordningens artikel 18.
            </p>
            <p>
              {' '}
              Du har ret til at klage til Datatilsynet over vores behandling af
              personoplysninger vedrørende dig, hvis du mener, at vi behandler
              oplysningerne i strid med lovgivningen.
            </p>
            <p>
              {' '}
              Ønsker du at gøre brug er ovenstående rettigheder / er du på nogen
              måde utilfreds med vores måde at behandle personoplysninger på, er
              du velkommen til at kontakte os. – Vore kontaktoplysninger står
              ovenfor.
            </p>
            <p>
              {' '}
              Ønsker du at kontakte Datatilsynet, kan du finde nærmere
              information om dem på:{' '}
              <a
                href="https://www.datatilsynet.dk/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.datatilsynet.dk/
              </a>
            </p>
          </td>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
