import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { strapiUrl } from '../config/env';

export const SignUpForApartment = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const [hasSubmitCompleted, setHasSubmitCompleted] = React.useState(false);

  const testSchema = Yup.object().shape({
    name: Yup.string().required('Angiv dit navn'),
    email: Yup.string()
      .email('Skriv en gyldig email')
      .required('Angiv din email'),
    phone: Yup.number()
      .typeError('Nummeret er ugyldigt')
      .required('Angiv dit telefon nummer'),
    rooms: Yup.number()
      .typeError('Brug kun tal')
      .required('Angiv hvor mange værelser du ønsker'),
    rent: Yup.number()
      .typeError('Nummeret er ugyldigt')
      .required('Angiv hvor meget du vil give i husleje'),
    interest: Yup.date()
      .typeError('Nummeret er ugyldigt')
      .required('Angiv hvor lang tid du er interesseret'),
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Skriv dig op</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            validationSchema={testSchema}
            initialValues={{
              name: '',
              email: '',
              phone: '',
              rooms: '',
              rent: '',
              interest: '',
            }}
            onSubmit={async (values, actions) => {
              try {
                const payload = {
                  Navn: values.name,
                  Telefon: values.phone,
                  Email: values.email,
                  Max_Husleje: values.rent,
                  Antal_Rum: values.rooms,
                  Interesseret_indtil: values.interest,
                };

                await axios.post(`${strapiUrl}/mulige-lejeres`, payload);
                setHasSubmitCompleted(true);
              } catch (error: any) {
                alert(error.message);
              }
            }}
          >
            {(formProps) =>
              hasSubmitCompleted ? (
                <Text>Du har skrevet dig op!</Text>
              ) : (
                <Form>
                  <Field name="name">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="name">Navn:</FormLabel>
                        <Input {...field} id="name" placeholder="Navn" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="email">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                        isRequired
                      >
                        <FormLabel htmlFor="email" mt={'10px'}>
                          Email:
                        </FormLabel>
                        <Input {...field} id="email" placeholder="Email" />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="phone">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.phone && form.touched.phone}
                      >
                        <FormLabel htmlFor="phone" mt={'10px'}>
                          Telefon nummer:
                        </FormLabel>
                        <Input
                          {...field}
                          id="phone"
                          placeholder="Telefon nummer"
                        />
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="rooms">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.rooms && form.touched.rooms}
                        isRequired
                      >
                        <FormLabel htmlFor="rooms" mt={'10px'}>
                          Antal værelser:
                        </FormLabel>

                        <Select
                          {...field}
                          id="rooms"
                          placeholder="Vælg hvor mange værelser"
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Select>
                        <FormErrorMessage>{form.errors.rooms}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="rent">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        typ
                        isInvalid={form.errors.rent && form.touched.rent}
                        isRequired
                      >
                        <FormLabel htmlFor="rent" mt={'10px'}>
                          Månedlig leje:
                        </FormLabel>
                        <Input
                          {...field}
                          id="rent"
                          placeholder="DKK"
                          type="number"
                        />
                        <FormErrorMessage>{form.errors.rent}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="interest">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.interest && form.touched.interest
                        }
                        isRequired
                      >
                        <FormLabel htmlFor="interest" mt={'10px'}>
                          Interesseret indtil
                        </FormLabel>
                        <Input
                          type="date"
                          {...field}
                          min={new Date()}
                          id="interest"
                          placeholder="interest"
                        />
                        <FormErrorMessage>
                          {form.errors.interest}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    mt={'10px'}
                    type="submit"
                    isLoading={formProps.isSubmitting}
                  >
                    Send
                  </Button>
                </Form>
              )
            }
          </Formik>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
