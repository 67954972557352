import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
  Link as ChakraLink,
} from '@chakra-ui/react';
import type { FC } from 'react';
import React from 'react';
import { OutboundLink } from 'react-ga';
import '../App.scss';
import useGaTracker from '../components/PageTracking';
import { SignUpForApartment } from '../components/SignUpForApartment';
import { ColoredLine } from './Ejendomme';

const Kontakt: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useGaTracker();
  return (
    <>
      <Flex
        pr={'70px'}
        pl={'70px'}
        w={'100%'}
        direction={['row', 'column']}
        mt={'20px'}
      >
        <Heading
          m={'auto'}
          mb={'20px'}
          fontWeight={'normal'}
          fontStyle={'normal'}
          fontSize={['8vw', '4vw']}
        >
          Kontakt
        </Heading>
        <ColoredLine />
      </Flex>
      <Flex w={['100%','70%']} flexDir={['column', 'row']} justifyContent={'space-around'} mt={'3vw'}>
        <Flex
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={['100%', '18vw']}
          h={['auto', '23vw']}
          borderRadius={[0,'8px']}
          my={'auto'}
          justifyContent={'flex-end'}
          verticalAlign={'auto'}
          py={['10vw', '0vw']}
        >
          <Heading mt={'1.5vw'} fontSize={['12vw', '2vw']} mb={['5vw', '0vw']}>
            Ledige Lejemål
          </Heading>
          <Box px={'2vw'} m={'auto'} align="center">
            <Text
              mb={['4vw', '2vw']}
              align={'center'}
              fontSize={['7vw', '1.3vw']}
            >
              Se vores ledige lejemål på Bolig Portalen
            </Text>

            <OutboundLink
              eventLabel="Link til Bolig Portal - Header"
              to={'https://www.boligportal.dk/dl/toft/'}
              target="_blank"
            >
              <Button h={['12vw', '3vw']} w={'70%'} fontSize={['5vw', '1vw']}>
                Se ledige lejemål
              </Button>
            </OutboundLink>
          </Box>
        </Flex>
        <Flex
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={['100%', '20vw']}
          h={['auto', '30vw']}
          borderRadius={[0,'8px']}
          m={'auto'}
          mt={['2vw',0]}
          verticalAlign={'auto'}
        >
          {' '}
          <Heading mt={['5vw', '1.5vw']} fontSize={['12vw', '2.5vw']}>
            Kontakt os
          </Heading>
          <Box px={'2vw'} m={'auto'}>
            <Text
              mt={['4vw', '1vw']}
              fontWeight={'semibold'}
              mb={'0.3vw'}
              fontSize={['7vw', '1.4vw']}
            >
              Udlejer
            </Text>
            <Text fontSize={['7vw', '1.3vw']}>Torben Toft</Text>
            <Text fontSize={['7vw', '1.3vw']}>Tlf: 40 30 34 35</Text>
            <ChakraLink
              fontSize={['7vw', '1.3vw']}
              href={'mailto:info@toftejendomme.dk'}
              isExternal
            >
              info@toftejendomme.dk
            </ChakraLink>{' '}
            <Text fontSize={['7vw', '1.3vw']}>Mandag-fredag ml. 9-12</Text>
          </Box>
          <Box pb={'5vw'} px={'2vw'} m={'auto'}>
            <Text
              mt={['4vw', '1vw']}
              fontWeight={'semibold'}
              mb={'0.3vw'}
              fontSize={['7vw', '1.4vw']}
            >
              Vicevært
            </Text>
            <Text fontSize={['7vw', '1.3vw']}>Janus Skov Nielsen</Text>
            <Text fontSize={['7vw', '1.3vw']}>Tlf: 30 45 01 64</Text>
            <Text fontSize={['7vw', '1.3vw']}>Mandag-fredag ml. 9-10</Text>
          </Box>
        </Flex>
        <Flex
          flexDir="column"
          justify={'left'}
          align={'center'}
          bg={'#D1CABD'}
          w={['100%', '18vw']}
          h={['auto', '23vw']}
          borderRadius={[0,'8px']}
          my={'auto'}
          ml={'0px'}
          verticalAlign={'auto'}
          py={['10vw', '0vw']}
          mt={['2vw','inherit']}
        >
          <Heading
            mt={'1.5vw'}
            fontSize={['12vw', '2vw']}
            mb={['5vw', '0vw']}
            w="80%"
            align="center"
          >
            Søger du en bolig?
          </Heading>
          <Box px={'2vw'} m={'auto'} align="center">
            <Text mb={['4vw', '2vw']} fontSize={['7vw', '1.3vw']}>
              Skriv dig op til en bolig. Så kontakter vi dig så snart vi har den
              rette ledig.
            </Text>

            <Button
              h={['12vw', '3vw']}
              w={'70%'}
              fontSize={['5vw', '1vw']}
              onClick={onOpen}
            >
              Skriv dig op!
            </Button>
          </Box>
        </Flex>
      </Flex>

      <SignUpForApartment isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};

export default Kontakt;
